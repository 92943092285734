//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import _      from 'lodash';
import moment from 'moment';

import NumberFormat from '@helper/NumberFormat';

class Formatter {
    static formatToCurrency(digits = 0) {
        return (value) => {
            if (_.isNil(value)) {
                return null;
            }

            return NumberFormat.formatToEuroString(value, digits);
        };
    }

    static formatToSquareMeter(value) {
        if (_.isNil(value)) {
            return null;
        }

        return NumberFormat.format(
            value,
            {
                suffix:       I18n.t('format.squareMeter'),
                decimalScale: 2,
            },
        );
    }

    static formatToDate(value) {
        if (_.isNil(value)) {
            return null;
        }

        return moment(value).format('DD.MM.YYYY');
    }

    static formatToDateTime(value) {
        if (_.isNil(value)) {
            return null;
        }

        return moment(value).format('DD.MM.YYYY HH:mm');
    }

    static formatToPercent(value) {
        if (_.isNil(value)) {
            return null;
        }

        return NumberFormat.format(
            value,
            {
                suffix: I18n.t('format.percentSuffix'),
            },
        );
    }

    static formatToPercentDigits(decimalScale) {
        return (value) => {
            if (_.isNil(value)) {
                return null;
            }

            return NumberFormat.format(
                value,
                {
                    suffix: I18n.t('format.percentSuffix'),
                    decimalScale,
                },
            );
        };
    }

    static formatToInterval(value) {
        if (_.isNil(value)) {
            return null;
        }

        return I18n.t(
            'format.interval',
            {
                value,
            },
        );
    }

    static formatBoolean(value) {
        if (_.isNil(value)) {
            return null;
        }

        return I18n.t((
            value ?
                'yes' :
                'no'
        ));
    }

    static formatTranslated(translationContext) {
        return (value) => {
            if (_.isNil(value)) {
                return null;
            }

            return I18n.t(`${translationContext}.${_.toLower(value)}`);
        };
    }
}

export default Formatter;

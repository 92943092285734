//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames      from 'classnames';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';
import styled          from 'styled-components';

import LogoLight            from '@assets/images/logo_light.png';
import { svgColor }         from '@helper/StyledMixins';
import Url                  from '@helper/Url';
import useAppTheme          from '@hooks/AppTheme';
import useNavigationItems   from '@hooks/NavigationItems';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Icon                 from '@stateless/atomic/Icon';
import IconType             from '@stateless/atomic/Icon/IconType';
import { useLayoutActions } from '@store/slices/layout';

import styles from './styles.module.scss';

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const SidebarWrapper = styled.nav`
    background-color: ${(props) => props.theme.primaryColor} !important;
`;

// eslint-disable-next-line rulesdir/no-shortcut-variable-name
const SidebarItemWrapper = styled.li`
    ${(props) => svgColor(props.theme.textColor)}

    color: ${(props) => props.theme.textColor} !important;

    &:hover,
    &.active
    {
        ${(props) => svgColor(props.theme.secondaryColor)}

        background-color: ${(props) => props.theme.backgroundColor} !important;
        color: ${(props) => props.theme.secondaryColor} !important;
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const Sidebar = () => {
    const history                                    = useHistory();
    const dispatch                                   = useDispatch();
    const { toggleSidebar }                          = useLayoutActions(dispatch);
    const sidebarContracted                          = useSelector((state) => state.layout.sidebarContracted);
    const { navigationItems, bottomNavigationItems } = useNavigationItems(history);
    const appTheme                                   = useAppTheme();
    const sidebarLogo                                = useMemo(() => {
        const appThemeImagePath = appTheme?.image?.path;

        if (appThemeImagePath) {
            return Url.backendImage(appThemeImagePath);
        }

        return LogoLight;
    }, [appTheme]);

    function onSidebarToggleClicked() {
        toggleSidebar();
    }

    function renderDashboardItems(items) {
        return items.map((item) => {
            const { route, isActive, onClick, iconType, text } = item;

            return (
                <SidebarItemWrapper
                    key={route}
                    className={classNames(
                        styles.sidebarItem,
                        {
                            'active':                isActive,
                            [styles.itemContracted]: sidebarContracted,
                        },
                    )}
                    onClick={onClick}
                >
                    <Icon
                        iconType={iconType}
                    />
                    <span className={styles.sidebarItemText}>
                        {text}
                    </span>
                </SidebarItemWrapper>
            );
        });
    }

    return (
        <SidebarWrapper
            className={classNames(
                styles.sidebar,
                {
                    [styles.contracted]: sidebarContracted,
                },
            )}
        >
            <div className={styles.sidebarHeader}>
                <img
                    src={sidebarLogo}
                    alt="LogoLight"
                />
                <Button
                    onClick={onSidebarToggleClicked}
                    type={ButtonType.icon}
                    iconLeft={IconType.menu}
                    customColorLeft={appTheme.textColor}
                />
            </div>
            <div className={styles.sidebarContent}>
                <ul>
                    {renderDashboardItems(navigationItems)}
                </ul>
                <ul>
                    {renderDashboardItems(bottomNavigationItems)}
                </ul>
            </div>
        </SidebarWrapper>
    );
};

export default Sidebar;

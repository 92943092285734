//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    sidebarContracted: true,
});

const layoutSlice = createSlice({
    name:     'layout',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            return update(state, {
                sidebarContracted: {
                    $set: !state.sidebarContracted,
                },
            });
        },
    },
});

export const LayoutActions = layoutSlice.actions;

export const LayoutReducer = layoutSlice.reducer;

export const useLayoutActions = (dispatch) => bindActionCreators(LayoutActions, dispatch);

export default layoutSlice;
